<template>
  <div class="pageContainer" style="" >
    <div
    class="row   position-relative m-0 p-0 w-100 big-div-quiz"
   
    
    :style="getPageDesign()"
  >
    <!-- BackGroundDiv start-->
  
    <div
       class="bg lazyload"
         
      v-if="pageDesign.pageStyles.imageAlign == 'no' && getImageSrc(pageDesign.imageBlock, isDesktopView) !== ''"
     
      :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
:style="
getbackgroundImage(
IsImageAdded,
pageDesign.imageBlock,
pageDesign.pageStyles.imageAlign , isDesktopView
) + getBgDivStyles()
"
    ></div>
    <!-- BackGroundDiv end-->
    <div
      class=" px-0 px-0"
      v-show="showLeftImage"
      :class="[{
        'col-12 col-lg-12 col-xs-12': !isDesktopView,
        'image-division col-6': isDesktopView,
      }]"
       :style="calculateImageWidth( pageDesign.imageBlock , isDesktopView , IsImageVariableWidthActive)"
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
          :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
   :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                    IsImageVariableWidthActive,
                    pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${order}`,
              getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
            alt
/>
        </div>
      </div>
    </div>

    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      class=" "
      :style="
        `zoom: ${calcZoom} ;`
        + calculateContentWidth( pageDesign.imageBlock , isDesktopView ,IsImageVariableWidthActive,pageDesign.pageStyles)
      "
    >
      <div>
        <div
          v-for="(pro, ind) in pageDesign.blocksArray"
          :key="'preview' + ind"
          class=""
        >
          <div
            id="preview"
            :style="getHeightWidth(pro.style)"
            class="content"
            style="position: relative"
          >
            <div
              v-if="pro.type == 'text'"
              :style="
                getColorCss(pro.style) +
                getPaddingCss(pro.style) +
                getMarginCss(pro.style,isDesktopView) +
                getColorWithOpacity(pro.style) +
                getTextRotate(pro.style) +
                getLineHeight(pro.style) +
                getTextAlign(pro.style) +
                getFontStyle(pro.style,isDesktopView) 
              "
              v-html="pro.content"
            ></div>
             <div
                    v-if="pro.type == 'tooltip'"
              :style="
                `display:flex;justify-content: ${pro.style.position} ;  `
              "
                    
                  >
                    <button :style="getButtonCss(pro.style,isDesktopView) + getCircularBtn(pro.isBtnRounded)" >
                      <span v-if="pro.useIcon" >
                        <BIcon  :icon="pro.selectedIcon" />
                      </span>
                     
                      <span
                      v-else
                        :style="{
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        }"
                      >
                        {{ pro.text }}
                      </span>
                     
                     
                      
                    </button>
                    <ToolTipComp  v-if="pro.isTooltipVisible" :tooltipData='pro' :isDesktopView="isDesktopView" />

                  
                  </div>
                  <div
               v-if="pro.type == 'timer'"
              :style="
                `display:flex;justify-content: ${pro.style.position} ; flex-direction:column; `
              "
            >
            <Timer :pro="pro" :isDesktopView="isDesktopView" :isEditor="false" @endCounter="endCounterAction"/>
            
            </div>
            <div
               v-if="pro.type == 'button'"
              :style="
                `display:flex;justify-content: ${pro.style.position} ; `
              "
            >
              <button
                :style="getButtonCss(pro.style,isDesktopView)"
              >
                <span
                  :style="{
                    opacity: `${pro.style.textOpacity}%`,
                    fontSize: `${pro.style.fontSize}px`,
                  }"
                >
                  {{ pro.text }}
                </span>
                {{ pro.style.alignItems }}
              </button>
            </div>

            <div   v-if="pro.type == 'html'"
                    :style="
                     
                      getMarginCss(pro.style,isDesktopView) +
                      getColorWithOpacity(pro.style) +
                      getTextRotate(pro.style)  +
                      getTextAlign(pro.style) 
                    ">
              <HtmlElement  :pro="pro" :isDesktopView="isDesktopView"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class=" px-0 px-0"
      v-show="showRightImage"
     :class="[{
'col-12 col-lg-12 col-xs-12': !isDesktopView,
'image-division col-6': isDesktopView,
}]"
 :style="calculateImageWidth( pageDesign.imageBlock , isDesktopView , IsImageVariableWidthActive)"
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
             <LazyImage
             :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
                  :style="
                  getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                    IsImageVariableWidthActive,
                    pageDesign.imageBlock
              ) +
                  getImageOpacity(pageDesign.imageBlock.style) +
                  getImageRotation(pageDesign.imageBlock.style)
                "
                :class="[
                  `pic-${order}`,
                  getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                    ? 'default-bg'
                    : '',
                ]"
                :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
                alt
                  />
        </div>
      </div>
    </div>

    
  </div>
  </div>
</template>

<script>
import cssFunctionMixin from '../customize/mixin/cssFunction'

// import HtmlElement from "./HtmlElement.vue"
// import Timer from "./components/Timer.vue"
// import ToolTipComp from "./ToolTipComp.vue"
export default {
  components:{
    HtmlElement: () =>
      import(
        /* webpackChunkName: "HtmlElement" */ "../quizPreviewComp/HtmlElement.vue"
      ),
    Timer: () =>
      import(
        /* webpackChunkName: "Timer" */ "../quizPreviewComp/components/Timer.vue"
      ),
    ToolTipComp: () =>
      import(
        /* webpackChunkName: "ToolTipComp" */ "../quizPreviewComp/ToolTipComp.vue"
      ),
  },
props:['pageDesign','order','height','width',"mode"],
mixins: [cssFunctionMixin],
data(){
  return{
    isDesktopView:true
  }
},
computed:{
  calcZoom(){
    if(this.mode == 'firstPage'){
       return this.IsImageAdded && ( this.showLeftImage || this.showRightImage) ? '40%' : `${this.responsiveZoomWithoutImage}%`
    } else {
      return this.IsImageAdded && ( this.showLeftImage || this.showRightImage) ? '10%' : '15%'
    }
  },
     responsiveZoomWithoutImage() {
      // Get the current window width
      const windowWidth = window.innerWidth;
     
      // Calculate the proportional zoom percentage based on the ratio of window width to 1920
      const proportionalZoom = Math.floor((windowWidth / 1920) * 45);

      // Ensure the calculated zoom percentage is not lower than the baseZoom
      const zoomPercentage = Math.max(45, proportionalZoom);

      return zoomPercentage;
    },
showLeftImage() {
      return (
        (this.isDesktopView && this.pageDesign.imageBlock.src !== '' &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },
     IsImageAdded() {
      if (this.getImageSrc(this.pageDesign.imageBlock , this.isDesktopView) !== "") return true;
      return false;
    },
    IsImageVariableWidthActive(){
return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no" ? true : false
},
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
     return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
},
methods:{

  endCounterAction(){
    return true
  },
   getBgDivStyles() {
      return `position:absolute;top: 0;
        bottom: 0;
        left: 0;
        right: 0;width: 100%;
          height: 100%; z-index: 0;`;
    },
  getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; width:" + this.width + "px;height:"+ this.height +"px;overflow:hidden;"+ (this.mode == "firstPage"
          ? " border-top-left-radius: 12px; border-top-right-radius: 12px;"
          : "");

      return design;
    },
},
mounted(){
  this.$emit('loaded');
}
}
</script>

<style scoped>
.pageContainer{
  /* width: 100%;
  height: 100%; */
  max-height :100%;
  max-width :100%;
  z-index:-1000;
  pointer-events: none;

}
</style>